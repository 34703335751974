import React, {Suspense} from 'react'
import ReactDOM from 'react-dom/client'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'

const App = React.lazy(() => import('./App'))
const ChatApp = React.lazy(() => import('./ChatApp'))
const TheScribeAiApp = React.lazy(() => import('./TheScribeAi/App'))
const TheScribeAiWebsite = React.lazy(() => import('./TheScribeAi/Website'))
const TheScribeChatApp = React.lazy(() => import('./TheScribeChat/App'))
const TheScribeChatWebsite = React.lazy(() => import('./TheScribeChat/Website'))

const root = ReactDOM.createRoot(document.getElementById('root'))
const getApp = (ovenAppId) => {
  switch(ovenAppId) {
    case 'fn-ai-io':
      return <ChatApp />
    case 'app-thescribe-chat':
      return <TheScribeChatApp />
    case 'thescribe-chat':
      return <TheScribeChatWebsite />
    case 'app-thescribe-ai':
      return <TheScribeAiApp />
    case 'thescribe-ai':
      return <TheScribeAiWebsite />
    default:
      return <Suspense><App /></Suspense>
  }
}
const ovenAppId = process.env.REACT_APP_OVEN_APP_ID
root.render(
  <React.StrictMode>
    <Suspense>
      {getApp(ovenAppId)}
    </Suspense>
  </React.StrictMode>
)
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
